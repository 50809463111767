import React from 'react'
import { createBulk } from '@/apis/staffs'
import ButtonCreateStaffs from '@/components/ButtonCreateStaffs'

const Element = () => {
  const onCreateBulk = async (dataSource) => {
    await createBulk({ items: dataSource })
  }

  return <ButtonCreateStaffs onCreateBulk={onCreateBulk} />
}

export default Element
