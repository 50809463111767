import { GET, POST, PUT, DELETE } from '../services/axios'

export const find = async ({ page, limit, keyword, status }) => {
  return await GET(`/students?page=${page}&limit=${limit}&keyword=${keyword}&status=${status}`)
}

export const findOne = async (id) => await GET(`/students/${id}`)

export const create = async (formData) => await POST('/students', formData)

export const createBulk = async (formData) => await POST('/students/import', formData)

export const update = async (id, formData) => await PUT(`/students/${id}`, formData)

export const remove = async () => await DELETE('/students')
