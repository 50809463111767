import { useMemo } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { Table, Space, Button } from 'antd'
import { EditOutlined, IdcardOutlined } from '@ant-design/icons'
import Staff from './Staff'
import useStaffs from '@/hooks/useStaffs'

const Element = ({ onEdit, showModalStaffCards }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useStaffs()

  const columns = useMemo(() => {
    return [
      {
        title: 'Nhân viên',
        dataIndex: 'staff',
        key: 'staff',
        width: '30%',
        render: (text, rowData, index) => {
          return <Staff data={rowData} />
        },
      },
      {
        title: 'Mã số',
        dataIndex: 'code',
        key: 'code',
        width: '15%',
      },
      {
        title: 'Chức vụ',
        dataIndex: 'positionEN',
        key: 'positionEN',
        width: '25%',
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '15%',
        render: (text, rowData, index) => {
          return (
            <Space wrap>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  onEdit(rowData)
                }}
              />

              <Button
                icon={<IdcardOutlined />}
                onClick={() => {
                  showModalStaffCards([rowData])
                }}
              />
            </Space>
          )
        },
      },
    ]
  }, [])

  return (
    <Table
      rowKey="code"
      dataSource={data.dataSource}
      columns={columns}
      pagination={{
        current: data.current_page,
        total: data.total,
        onChange: (page, pageSize) => {
          searchParams.set('page', page)
          searchParams.set('limit', pageSize)
          navigate(`${location.pathname}?${searchParams.toString()}`)
        },
      }}
    />
  )
}

export default Element
