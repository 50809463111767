import React from 'react'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section08 = ({ data }) => {
  return (
    <Section>
      <Header>
        <Title>Kết quả Khám Nội khoa</Title>
      </Header>
      <Main>
        <Row>
          <Col span={4}>
            <div className="text-blue">Tuần hoàn</div>
          </Col>

          <Col span={4} className="border-right">
            <div className="text-blue bold"></div>
          </Col>

          <Col span={4}>
            <div className="text-blue">Hô hấp</div>
          </Col>

          <Col span={4} className="border-right">
            <div className="text-blue bold">{data['Hô Hấp']}</div>
          </Col>

          <Col span={4}>
            <div className="text-blue">Da liễu</div>
          </Col>

          <Col span={4}>
            <div className="text-blue bold">{data['Da liễu']}</div>
          </Col>

          <Col span={4}>
            <div className="text-blue">Tiêu hóa</div>
          </Col>

          <Col span={4} className="border-right">
            <div className="text-blue bold">{data['Tiêu hóa']}</div>
          </Col>

          <Col span={4}>
            <div className="text-blue">Thận - tiết niệu</div>
          </Col>

          <Col span={4} className="border-right">
            <div className="text-blue bold">{data['CQSD Tiết Niệu']}</div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section08
