import styled from 'styled-components'

export const Section = styled.section`
  height: 100%;
  background-color: #ef5124;
  font-size: 0.75rem;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
  overflow: hidden;

  .border-left{
    border-color: #ffffff;
  }
`

export const Main = styled.main`

  .ant-col {
    padding: 0.5rem 0.375rem;
  }
`
