import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Form } from 'antd'
import { AVATAR } from '../../../services/axios'
import { Section, Element, Content, Name, Number, Code } from './styles'
import src from './logo.png'
import { BASE_URL } from '@/constants'

const PrintElement = ({ data = {} }) => {
  const [avatar, setAvatar] = useState('')
  const [formData, setFormData] = useState({ line: 2, size: 24 })

  const name = useMemo(() => {
    const chars = data.name.split(' ')

    if (chars.length >= 4 && formData.line === 2) {
      chars.splice(2, 0, '<br>')
      return `${chars.join(' ')} <div>${data.positionEN}</div>`
    }

    return `${data.name} <div>${data.positionEN}</div>`
  }, [data.name, formData.line])

  const filename = useMemo(() => {
    return `${data.code} - ${data.name}`
  }, [data])

  useEffect(() => {
    AVATAR(`${BASE_URL}/avatar/${data.code}`).then((res) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(res.data)
      reader.onload = () => {
        setAvatar(reader.result)
      }
    })
  }, [data.code])

  const onValuesChange = (values) => {
    setFormData({
      ...formData,
      ...values,
    })
  }

  return (
    <Section>
      <Form
        layout="vertical"
        initialValues={formData}
        onValuesChange={onValuesChange}
      >
        <Row gutter={[24]}>
          <Col span="12">
            <Form.Item name="line" label="Số dòng tối đa cho tên">
              <Number min={1} max={2} />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item name="size" label="Cỡ chữ của chức vụ">
              <Number min={16} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Element className="print" data-filename={filename}>
        <Content>
          <img src={src} alt="" />
          <img src={avatar} alt="" />
          <Name size={formData.size} dangerouslySetInnerHTML={{ __html: name }} />
        </Content>

        <Code>{data.code}</Code>

        <div className="deco-01" />
        <div className="deco-02" />
      </Element>
    </Section>
  )
}

export default PrintElement
