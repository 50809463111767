import { useEffect } from 'react'
import { Form, Input, Select, Modal } from 'antd'
import useStudent from '@/hooks/useStudent'

const ModalFormStudent = ({ visible, setVisible, formData }) => {
  const [form] = Form.useForm()
  const [loading, onSubmit] = useStudent()

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (visible && formData.id) {
      form.setFieldsValue(formData)
    }
  }, [visible, formData])

  useEffect(() => {
    if (!loading) {
      setVisible(false)
    }
  }, [loading])

  const onOk = async () => {
    const values = await form.validateFields()
    onSubmit(formData.id, values)
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Modal open={visible} confirmLoading={loading} onOk={onOk} onCancel={onCancel}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="code"
          label="Mã hoc sinh"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label="Tên họ"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="className"
          label="Lớp"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="birthday"
          label="Ngày sinh"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="status"
          label="Trạng thái"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Select
            defaultValue="active"
            options={[
              {
                value: 'active',
                label: 'Active',
              },
              {
                value: 'inactive',
                label: 'Inactive',
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalFormStudent
