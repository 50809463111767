import React from 'react'
import readXlsxFile from 'read-excel-file'
import { Upload, Button, message } from 'antd'

const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Tập tin vượt quá 2MB')
    return false
  }

  return isLt2M
}

const decodeData = (data) => {
  return {
    code: data[1],
    name: data[2],
    department: data[3],
    positionEN: data[4],
    positionVI: data[5],
    status: 'active'
  }
}

const Element = ({ onCreateBulk }) => {
  const customRequest = (info) => {
    const { file } = info

    readXlsxFile(file).then((rows) => {
      const dataSource = []
      for (let i = 6; i < rows.length; i++) {
        const data = rows[i]

        if(data[0]) {
          const staffData = decodeData(data)
          dataSource.push(staffData)
        }
      }

      onCreateBulk(dataSource)
    })
  }

  return (
    <Upload
      name="file"
      accept=".xlsx"
      multiple={false}
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
    >
      <Button>Nhập dữ liệu từ Excel</Button>
    </Upload>
  )
}

export default Element
