import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import { BASE_URL } from '@/constants'

const Element = () => {
  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList)
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  return (
    <Upload
      name="file"
      action={`${BASE_URL}/upload`}
      accept=".jpg"
      showUploadList={false}
      multiple={true}
      onChange={onChange}
    >
      <Button icon={<UploadOutlined />}>Tải hình ảnh</Button>
    </Upload>
  )
}

export default Element
