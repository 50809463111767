import React from 'react'
import { Button } from 'antd'
import useStudents from '@/hooks/useStudents'

const Element = ({ showModalStudentCards }) => {
  const { data } = useStudents()

  const onClick = () => {
    showModalStudentCards(data.dataSource)
  }

  return (
    <Button onClick={onClick}>
      In toàn bộ
    </Button>
  )
}

export default Element
