import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Input } from 'antd'

const Element = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has('keyword')) {
      setKeyword(searchParams.get('keyword'))
    }
  }, [location.search])

  const onChange = (e) => {
    setKeyword(e.target.value)
  }

  const onSearch = (value) => {
    if (value === '') {
      navigate(`${location.pathname}`)
    } else {
      navigate(`${location.pathname}?keyword=${value}`)
    }
  }

  return (
    <Input.Search
      value={keyword}
      allowClear
      onChange={onChange}
      onSearch={onSearch}
    />
  )
}

export default Element
