import styled from 'styled-components'
import { InputNumber } from 'antd'

export const Section = styled.section`
  margin-bottom: 2rem;
`

export const Element = styled.div`
  position: relative;
  background-color: #112d51;
  text-align: center;
  overflow: hidden;

  .deco-01 {
    position: absolute;
    top: 15%;
    left: -5rem;
    width: 8rem;
    height: 8rem;
    background-color: #ff4c00;
    border-radius: 2rem;
    transform: rotate(45deg);
  }

  .deco-02 {
    position: absolute;
    bottom: 25%;
    right: -8rem;
    width: 10rem;
    height: 10rem;
    background-color: #036880;
    border-radius: 2rem;
    transform: rotate(45deg);
  }
`

export const Content = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 4.5rem;

  img {
    display: block;
    height: auto;
    margin: auto;
  }

  img:nth-child(1) {
    width: 17rem;
    transform: translateX(-12px);
    margin-bottom: 0.5rem;
  }

  img:nth-child(2) {
    width: auto;
    height: 22.5rem;
  }
`

export const Name = styled.div`
  height: 3.5rem;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 1.5rem;

  div {
    font-size: ${props => props.size}px;
    font-weight: 300;
  }
`

export const Code = styled.div`
  color: #ffffff;
  background-color: #ffce07;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 4.5rem;
`

export const Number = styled(InputNumber)`
  width: 100%;
`


