import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Select } from 'antd'

const OPTIONS = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
]

const Element = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [status, setStatus] = useState('active')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    
    if (searchParams.has('status')) {
      setStatus(searchParams.get('status'))
    }
  }, [location.search])

  const onChange = (value) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('status', value)

    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  return <Select value={status} options={OPTIONS} onChange={onChange} />
}

export default Element
