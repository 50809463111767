import { useState } from 'react'
import { Button } from 'antd'
import Layout from '@/layouts/Default'
import ButtonCreateStaffs from '@/containers/ButtonCreateStaffs'
import ButtonPrintStaffs from '@/components/ButtonPrintStaffs'
import TableStaffs from '@/components/TableStaffs'
import ModalStaffCards from '@/components/StaffCards/ModalPreview'
import ModalFormStaff from '@/components/ModalFormStaff'
import SearchBox from '@/components/SearchBox'
import SearchStatus from '@/components/SearchStatus'
import { Page, Header, Actions } from './styles'

const DEFAULT_STAFF = {
  name: '',
  country: '',
  countryCode: '',
  countryFlag: '',
  status: 'active',
}

const Element = () => {
  const [visibleModalForm, setVisibleModalForm] = useState(false)
  const [visibleModalStaffCards, setVisibleModalStaffCards] = useState(false)
  const [formData, setFormData] = useState({})
  const [selectedItems, setSelectedItems] = useState([])

  const onCreate = () => {
    setFormData(DEFAULT_STAFF)
    setVisibleModalForm(true)
  }

  const onEdit = (item) => {
    setFormData(item)
    setVisibleModalForm(true)
  }

  const showModalStaffCards = (items) => {
    setSelectedItems(items)
    setVisibleModalStaffCards(true)
  }

  return (
    <Layout>
      <Page>
        <Header>
          <h2>Quản lý nhân viên</h2>

          <Actions>
            <SearchBox />

            <SearchStatus />

            <div></div>

            <ButtonCreateStaffs />
            <ButtonPrintStaffs showModalStaffCards={showModalStaffCards} />
            <Button type="primary" onClick={onCreate}>Thêm mới</Button>
          </Actions>
        </Header>

        <TableStaffs
          onEdit={onEdit}
          showModalStaffCards={showModalStaffCards}
        />
      </Page>

      <ModalFormStaff
        visible={visibleModalForm}
        setVisible={setVisibleModalForm}
        formData={formData}
      />

      <ModalStaffCards
        visible={visibleModalStaffCards}
        setVisible={setVisibleModalStaffCards}
        printData={selectedItems}
      />
    </Layout>
  )
}

export default Element
