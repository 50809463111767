import React from 'react'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section03 = ({ data }) => {
  return (
    <Section>
      <Header>
        <Title>Kết quả khám huyết áp</Title>
      </Header>
      <Main>
        <Row>
          <Col span={6} className="border-bottom">
            <div className="text-blue semibold">Tâm thu</div>
          </Col>
          <Col span={6} className="border-right border-bottom">
            <div></div>
          </Col>

          <Col span={6}>
            <div className="text-blue semibold merge-2">Nhịp tim</div>
          </Col>

          <Col span={6}>
            <div className="merge-2"></div>
          </Col>

          <Col span={6}>
            <div className="text-blue semibold">Tâm trương</div>
          </Col>
          <Col span={6} className="border-right">
            <div></div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section03
