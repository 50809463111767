import { useState } from "react";
import { create, update } from '@/apis/students'

const useStudent = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (id, formData) => {
    setLoading(true)

    if (id) {
      update(id, formData).then((res) => {
        setLoading(false)
      })
    } else {
      create(formData).then((res) => {
        setLoading(false)
      })
    }
  }

  return [loading, onSubmit];
};

export default useStudent