import { useMemo } from 'react'
import { Avatar } from 'antd'
import { Section, Name, Code } from './styles'
import { BASE_URL } from '@/constants'

const Element = ({ data }) => {
  const src = useMemo(() => {
    return `${BASE_URL}/avatar/${data.code}`
  }, [data.code])

  return (
    <Section>
      <Avatar src={src} alt='' />

      <main>
        <Name>{data.name}</Name>
        <Code>{data.code}</Code>
      </main>
    </Section>
  )
}

export default Element
