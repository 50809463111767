import React from 'react'
import { createBulk } from '@/apis/students'
import ButtonCreateStudents from '@/components/ButtonCreateStudents'

const Element = () => {
  const onCreateBulk = async (dataSource) => {
    await createBulk({ items: dataSource })
  }

  return <ButtonCreateStudents onCreateBulk={onCreateBulk} />
}

export default Element
