import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { find } from '@/apis/staffs'

const useStaffs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    refetch()
  }, [searchParams])

  const refetch = () => {
    const keyword = searchParams.has('keyword') ? searchParams.get('keyword') : ''
    const status = searchParams.has('status') ? searchParams.get('status') : 'active'
    const page = searchParams.has('page') ? searchParams.get('page') : 1
    const limit = searchParams.has('limit') ? searchParams.get('limit') : 10

    find({ page, limit, keyword, status }).then((res) => {
      setData({
        ...res.data,
        dataSource: res.data.data
      })
    })
  }

  return { data, refetch };
};

export default useStaffs;