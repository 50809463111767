import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding: 0;
  }
`

export const PrintContainer = styled.div`
  max-height: 40rem;
  overflow-y: auto;
`
