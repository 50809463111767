import React from 'react'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section06 = ({ data }) => {
  return (
    <Section>
      <Header>
        <Title>Kết quả khám Răng - Hàm - Mặt</Title>
      </Header>
      <Main>
        <Row>
          <Col span={5}>
            <div className="text-blue">Bệnh về RHM</div>
          </Col>

          <Col span={5} className="border-right">
            <div className="text-blue bold">{data['Răng']}</div>
          </Col>

          <Col span={5}>
            <div className="text-blue">Bệnh về RHM khác</div>
          </Col>

          <Col span={9}>
            <div className="text-blue bold">{data['Răng Khác']}</div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section06
