import axios from 'axios'
import { BASE_URL } from '@/constants'

axios.defaults.baseURL = BASE_URL

export const GET = (url) => {
  const headers = {}

  return axios.get(url, { headers })
}

export const AVATAR = (url) => {
  return axios.get(url, { responseType: 'blob' })
}

export const POST = (url, formData) => {
  const headers = {}

  return axios.post(url, formData, { headers })
}

export const PUT = (url, formData) => {
  const headers = {}

  return axios.put(url, formData, { headers })
}

export const DELETE = (url) => {
  const headers = {}

  return axios.delete(url, { headers })
}
