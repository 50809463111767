import { useMemo } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { Table, Space, Button } from 'antd'
import { EditOutlined, IdcardOutlined, HeartOutlined } from '@ant-design/icons'
import Student from './Student'
import useStudents from '@/hooks/useStudents'

const Element = ({
  onEdit,
  showModalStudentCards,
  showModalHealthResults,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useStudents()

  const columns = useMemo(() => {
    return [
      {
        title: 'Học sinh',
        dataIndex: 'student',
        key: 'student',
        width: '30%',
        render: (text, rowData, index) => {
          return <Student data={rowData} />
        },
      },
      {
        title: 'Lớp',
        dataIndex: 'className',
        key: 'className',
        width: '15%',
      },
      {
        title: 'Sinh nhật',
        dataIndex: 'birthday',
        key: 'birthday',
        width: '25%',
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '15%',
        render: (text, rowData, index) => {
          return (
            <Space wrap>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  onEdit(rowData)
                }}
              />

              <Button
                icon={<IdcardOutlined />}
                onClick={() => {
                  showModalStudentCards([rowData])
                }}
              />

              <Button
                icon={<HeartOutlined />}
                onClick={() => {
                  showModalHealthResults([rowData])
                }}
              />
            </Space>
          )
        },
      },
    ]
  }, [])

  return (
    <Table
      rowKey="code"
      dataSource={data.dataSource}
      columns={columns}
      pagination={{
        pageSize: 25,
        current: data.current_page,
        total: data.total,
        onChange: (page, pageSize) => {
          searchParams.set('page', page)
          searchParams.set('limit', pageSize)
          navigate(`${location.pathname}?${searchParams.toString()}`)
        },
      }}
    />
  )
}

export default Element
