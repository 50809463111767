import React from 'react'
import { Row, Col } from 'antd'
import { Section, Main } from './styles'

const Section09 = ({ data }) => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col span={16}>
          <Section>
            <Main>
              <Row align="middle">
                <Col span={6} className="border-left">
                  <div className="text-white bold">BMI (kg/m2)</div>
                  <div className="text-white bold">Chỉ số khối cơ thể</div>
                  <div className="text-white italic">Body Mass Index</div>
                </Col>

                <Col span={2}>
                  <div className="text-white bold">{data['BMI (kg/m2)']}</div>
                </Col>

                <Col span={6} className="border-left">
                  <div className="text-white bold">PBF (%)</div>
                  <div className="text-white bold">Phần trăm chất béo</div>
                  <div className="text-white italic">Percent Body Fat</div>
                </Col>

                <Col span={2}>
                  <div className="text-white bold">{data['PBF']}</div>
                </Col>

                <Col span={6} className="border-left">
                  <div className="text-red bold">Điểm số cơ thể</div>
                  <div className="text-white bold">Điểm số cơ thể</div>
                  <div className="text-white italic">Body Score (points)</div>
                </Col>

                <Col span={2}>
                  <div className="text-white bold">{data['bodyScore']}</div>
                </Col>
              </Row>
            </Main>
          </Section>
        </Col>

        <Col span={8}>
          <Section>
            <Main>
              <Row>
                <Col span={16}>
                  <div className="text-white bold">Phân loại dinh dưỡng</div>
                </Col>

                <Col span={8}>
                  <div className="text-white bold">
                    <small>{data['Kết Luận Dinh Dưỡng']}</small>
                  </div>
                </Col>

                <Col span={16}>
                  <div className="text-white bold">Tình trạng bệnh tật</div>
                </Col>

                <Col span={8}>
                  <div className="text-white bold">
                    {data['Phân loại tình trạng bệnh tật']}
                  </div>
                </Col>

                <Col span={16}>
                  <div className="text-white bold">Phân loại sức khỏe</div>
                </Col>

                <Col span={8}>
                  <div className="text-white bold">
                    {data['Phân loại sức khỏe']}
                  </div>
                </Col>
              </Row>
            </Main>
          </Section>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Section09
