import React from 'react'
import { Button } from 'antd'
import useStaffs from '@/hooks/useStaffs'

const Element = ({ showModalStaffCards }) => {
  const { data } = useStaffs()

  const onClick = () => {
    showModalStaffCards(data.dataSource)
  }

  return (
    <Button onClick={onClick}>
      In toàn bộ
    </Button>
  )
}

export default Element
