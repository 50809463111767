import React, { useState } from 'react'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import PrintElement from '../PrintElement'
import { Modal, PrintContainer } from './styles'

const ModalPreview = ({ visible, setVisible, printData = [] }) => {
  const [loading, setLoading] = useState(false)

  const onOk = () => {
    setLoading(true)

    setTimeout(() => {
      const promises = []
      const filenames = []
      const elements = document.querySelectorAll('.print')

      elements.forEach((element) => {
        promises.push(html2canvas(element, { scale: 2 }))
        filenames.push(element.dataset.filename)
      })

      Promise.all(promises).then((values) => {
        setLoading(false)

        // if (values.length === 1) {
        //   values.forEach((canvas, index) => {
        //     const filename = filenames[index]
        //     const dataURL = canvas.toDataURL('image/png')

        //     const pdf = new jsPDF('p', 'in', [2.21, 3.45])
        //     pdf.addImage(dataURL, 'PNG', 0, 0, 2.21, 3.45, 'a', 'MEDIUM')
        //     pdf.save(`${filename}.pdf`)
        //   })
        // }

        // if (values.length > 1) {
        //   const pdf = new jsPDF('p', 'in', [2.21, 3.45])

        //   values.forEach((canvas, index) => {
        //     const dataURL = canvas.toDataURL('image/png')

        //     pdf.addImage(dataURL, 'PNG', 0, 0, 2.21, 3.45, 'a' + index, 'MEDIUM')
        //     pdf.addPage()
        //   })

        //   pdf.save('hinh-the.pdf')
        // }

        if (values.length === 1) {
          values.forEach((canvas, index) => {
            const filename = filenames[index]
            const dataURL = canvas.toDataURL('image/png')

            const pdf = new jsPDF('p', 'mm', [57, 89])
            pdf.addImage(dataURL, 'PNG', 0, 0, 57, 89, 'a', 'MEDIUM')
            pdf.save(`${filename}.pdf`)
          })
        }

        if (values.length > 1) {
          const pdf = new jsPDF('p', 'mm', [57, 89])

          values.forEach((canvas, index) => {
            const dataURL = canvas.toDataURL('image/png')

            pdf.addImage(dataURL, 'PNG', 0, 0, 57, 89, 'a' + index, 'MEDIUM')
            pdf.addPage()
          })

          pdf.save('hinh-the.pdf')
        }

        setVisible(false)
      })
    }, 1000)
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      title="Kiểm tra file PDF trước khi in"
      open={visible}
      confirmLoading={loading}
      onOk={onOk}
      onCancel={onCancel}
    >
      <PrintContainer>
        {printData.map((item) => (
          <PrintElement key={item.code} data={item} />
        ))}
      </PrintContainer>
    </Modal>
  )
}

export default ModalPreview
