import { useState } from 'react'
import { Button } from 'antd'
import Layout from '@/layouts/Default'
import ButtonCreateStudents from '@/containers/ButtonCreateStudents'
import ButtonPrintStudents from '@/components/ButtonPrintStudents'
import TableStudents from '@/components/TableStudents'
import ModalFormStudent from '@/components/ModalFormStudent'
import ModalStudentCards from '@/components/StudentCards/ModalPreview'
import ModalHealthResults from '@/components/HealthResults/ModalPreview'
import SearchBox from '@/components/SearchBox'
import SearchStatus from '@/components/SearchStatus'
import { Page, Header, Actions } from './styles'

const DEFAULT_STUDENT = {
  name: '',
  className: '',
  gender: '',
  birthday: '',
  status: 'active',
}

const Element = () => {
  const [visibleModalForm, setVisibleModalForm] = useState(false)
  const [visibleModalStudentCards, setVisibleModalStudentCards] = useState(false)
  const [visibleModalHealthResults, setVisibleModalHealthResults] = useState(false)
  const [formData, setFormData] = useState({})
  const [selectedStudents, setSelectedStudents] = useState([])

  const onCreate = () => {
    setFormData(DEFAULT_STUDENT)
    setVisibleModalForm(true)
  }

  const onEdit = (item) => {
    setFormData(item)
    setVisibleModalForm(true)
  }

  const showModalStudentCards = (items) => {
    setSelectedStudents(items)
    setVisibleModalStudentCards(true)
  }

  const showModalHealthResults = (items) => {
    setSelectedStudents(items)
    setVisibleModalHealthResults(true)
  }
  console.log(1111)
  return (
    <Layout>
      <Page>
        <Header>
          <h2>Quản lý học sinh</h2>

          <Actions>
            <SearchBox />

            <SearchStatus />

            <div></div>

            <ButtonCreateStudents />
            <ButtonPrintStudents showModalStudentCards={showModalStudentCards} />
            <Button type="primary" onClick={onCreate}>Thêm mới</Button>
          </Actions>
        </Header>

        <TableStudents
          onEdit={onEdit}
          showModalStudentCards={showModalStudentCards}
          showModalHealthResults={showModalHealthResults}
        />
      </Page>

      <ModalFormStudent
        visible={visibleModalForm}
        setVisible={setVisibleModalForm}
        formData={formData}
      />

      <ModalStudentCards
        visible={visibleModalStudentCards}
        setVisible={setVisibleModalStudentCards}
        printData={selectedStudents}
      />

      <ModalHealthResults
        visible={visibleModalHealthResults}
        setVisible={setVisibleModalHealthResults}
        printData={selectedStudents}
      />
    </Layout>
  )
}

export default Element
