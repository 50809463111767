import React, { useState, useMemo, useEffect } from 'react'
import { AVATAR } from '../../../services/axios'
import { Element, Content, Name, StudentId } from './styles'
import src from './logo.png'
import { BASE_URL } from '@/constants'

const PrintElement = ({ data = {} }) => {
  const [avatar, setAvatar] = useState('')

  useEffect(() => {
    AVATAR(`${BASE_URL}/avatar/${data.code}`).then((res) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(res.data)
      reader.onload = () => {
        setAvatar(reader.result)
      }
    })
  }, [data.code])

  const color = useMemo(() => {
    return Number(data.className) < 6 ? '#ee5125' : '#05AD59'
  }, [data.className])

  const name = useMemo(() => {
    const chars = data.name.split(' ')

    if (chars.length >= 4) {
      chars.splice(2, 0, '<br>')
      return chars.join(' ')
    }

    return data.name
  }, [data.name])

  const filename = useMemo(() => {
    return `${data.code} - ${data.name}`
  }, [data])

  return (
    <Element className="print" data-filename={filename}>
      <Content>
        <img src={src} alt="" />
        <img src={avatar} alt="" />
        <Name dangerouslySetInnerHTML={{__html: name }} />
      </Content>

      <StudentId color={color}>{data.code}</StudentId>

      <div className="deco-01" />
      <div className="deco-02" />
    </Element>
  )
}

export default PrintElement
