import styled from 'styled-components'

export const Layout = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  margin-bottom: 2rem;

  img {
    width: 8rem;
    height: auto;
  }

  a {
    font-weight: 700;
    margin-right: 2rem;
  }

  a:first-child {
    display: inline-block;
    background-color: #112d51;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const Main = styled.main`
  min-height: calc(100vh - 12rem);
`

export const Footer = styled.footer`
  font-weight: 300;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1.5rem;
`

export const Container = styled.div`
  width: 1200px;
  margin: auto;
`
