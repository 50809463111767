import React, { useState } from 'react'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import HealthTestResult from '../HealthTestResult'
import { Modal, PrintContainer } from './styles'

const ModalPreview = ({ visible, setVisible, printData = [] }) => {
  const [loading, setLoading] = useState(false)

  const onOk = () => {
    setLoading(true)

    setTimeout(() => {
      const promises = []
      const filenames = [] 
      const elements = document.querySelectorAll('.print')
      const classname = elements[0].dataset.class

      elements.forEach(element => {
        promises.push(html2canvas(element, { scale: 2 }))
        filenames.push(element.dataset.filename)
      })

      Promise.all(promises).then(values => {
        setLoading(false)

        if (values.length === 1) {
          values.forEach((canvas, index) => {
            const filename = filenames[index]
            const dataURL = canvas.toDataURL('image/png')

            const pdf = new jsPDF({ orientation: 'portrait' })
            pdf.addImage(dataURL, 'PNG', 0, 0, 210, 297, 'a', 'MEDIUM')
            pdf.save(`${filename}.pdf`)
          })
        }

        if (values.length > 1) {
          const pdf = new jsPDF({ orientation: 'portrait' })

          values.forEach((canvas, index) => {
            const dataURL = canvas.toDataURL('image/png')

            pdf.addImage(dataURL, 'PNG', 0, 0, 210, 297, 'a' + index, 'MEDIUM')
            pdf.addPage()
          })

          pdf.save(`${classname}.pdf`)
        }

        setVisible(false)
      })
    }, 1000)
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      title='Kiểm tra file PDF trước khi in'
      open={visible}
      confirmLoading={loading}
      width={1200}
      onOk={onOk}
      onCancel={onCancel}
    >
      <PrintContainer>
        {printData.map(item => (
          <HealthTestResult key={item.code} data={item} />
        ))}
      </PrintContainer>
    </Modal>
  )
}

export default ModalPreview
