import { useEffect } from 'react'
import { Form, Input, Select, Modal } from 'antd'
import useSubmitStaff from '@/hooks/useSubmitStaff'

const ModalFormStaff = ({ visible, setVisible, formData }) => {
  const [form] = Form.useForm()
  const [loading, onSubmit] = useSubmitStaff()

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (visible && formData.id) {
      form.setFieldsValue(formData)
    }
  }, [visible, formData])

  useEffect(() => {
    if (!loading) {
      setVisible(false)
    }
  }, [loading])

  const onOk = async () => {
    const values = await form.validateFields()
    onSubmit(formData.id, values)
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Modal open={visible} confirmLoading={loading} onOk={onOk} onCancel={onCancel}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="code"
          label="Mã nhân viên"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label="Tên họ"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="department"
          label="Bô phận"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="positionVI"
          label="Vị trí (Tiếng Việt)"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="positionEN"
          label="Vị trí (Tiếng Anh)"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="status"
          label="Trạng thái"
          rules={[{ required: true, message: 'Dữ liệu này là bắt buộc' }]}
        >
          <Select
            defaultValue="active"
            options={[
              {
                value: 'active',
                label: 'Active',
              },
              {
                value: 'inactive',
                label: 'Inactive',
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalFormStaff
