import React from 'react'
import { Row, Col } from 'antd'
import { Section, Header, Main, Title } from './styles'

const Section02 = ({ data }) => {
  return (
    <Section>
      <Header>
        <Title>
          <strong>Kết quả chỉ số cơ thể</strong> / Body Analysis
        </Title>
      </Header>
      <Main>
        <Row className="border-bottom">
          <Col span={3}>
            <div className="text-blue bold">Chiều cao</div>
            <div className="text-blue italic">Height (cm)</div>
          </Col>
          <Col span={2} className="flex-center">
            <div className="text-blue bold">{data.height}</div>
          </Col>
          <Col span={3}>
            <div className="text-blue bold">Cân nặng</div>
            <div className="text-blue italic">Weight (kg)</div>
          </Col>
          <Col span={2} className="flex-center border-right">
            <div className="text-blue bold">{data.weight}</div>
          </Col>
          <Col span={2} className="center border-right">
            <div className="text-blue bold">Giá trị</div>
            <div className="text-blue italic">Values</div>
          </Col>
          <Col span={4} className="center border-right">
            <div className="text-blue bold">Giới hạn phù hợp</div>
            <div className="text-blue italic">Limited</div>
          </Col>
        </Row>

        <Row>
          <Col span={6} className="border-right">
            <div className="text-blue semibold">
              Tổng lượng nước trong cơ thể
            </div>
            <div className="text-blue italic">
              Total amount of water in body
            </div>
          </Col>
          <Col span={4} className="border-right">
            <div className="text-blue semibold">Lượng nước</div>
            <div className="text-blue italic">Body Water (L)</div>
          </Col>
          <Col span={2} className="center border-right">
            <div className="text-blue bold">{data['bodyWater']}</div>
          </Col>
          <Col span={4} className="center border-right">
            <div className="text-blue">{data['bodyWaterLimit']}</div>
          </Col>
          <Col span={6}>
            <div className="text-blue bold">Cân nặng cần hướng tới</div>
            <div className="text-blue italic">Target Weight (kg)</div>
          </Col>
          <Col span={2} className="center">
            <div className="text-blue bold">{data['targetWeight']}</div>
          </Col>
        </Row>

        <Row>
          <Col span={6} className="border-right">
            <div className="text-blue semibold">Cần để xây dựng cơ bắp</div>
            <div className="text-blue italic">What I need to build Muscles</div>
          </Col>
          <Col span={4} className="border-right">
            <div className="text-blue semibold">Protein (kg)</div>
          </Col>
          <Col span={2} className="center border-right">
            <div className="text-blue bold">{data['protein']}</div>
          </Col>
          <Col span={4} className="center border-right">
            <div className="text-blue">{data['proteinLimit']}</div>
          </Col>
          <Col span={6}>
            <div className="text-blue bold">Số cân nặng cần kiểm soát</div>
            <div className="text-blue italic">Weight Control (kg)</div>
          </Col>
          <Col span={2} className="center">
            <div className="text-blue bold">{data['weightControl']}</div>
          </Col>
        </Row>

        <Row>
          <Col span={6} className="border-right">
            <div className="text-blue semibold">Năng lượng dư thừa đang có</div>
            <div className="text-blue italic">
              Where my excess energy is stored
            </div>
          </Col>
          <Col span={4} className="border-right">
            <div className="text-blue semibold">Lượng chất béo</div>
            <div className="text-blue italic">Body Fat Mass (kg)</div>
          </Col>
          <Col span={2} className="center border-right">
            <div className="text-blue bold">{data['bodyFat']}</div>
          </Col>
          <Col span={4} className="center border-right">
            <div className="text-blue">{data['bodyFatLimit']}</div>
          </Col>
          <Col span={6}>
            <div className="text-blue bold">Lượng cơ cần kiểm soát</div>
            <div className="text-blue italic">Muscle Control (kg)</div>
          </Col>
          <Col span={2} className="center">
            <div className="text-blue bold">{data['muscleControl']}</div>
          </Col>
        </Row>

        <Row>
          <Col span={6} className="border-right">
            <div className="text-blue semibold">Cần cho xương khoẻ</div>
            <div className="text-blue italic">What I need for strong bones</div>
          </Col>
          <Col span={4} className="border-right">
            <div className="text-blue semibold">Lượng khoáng chất</div>
            <div className="text-blue italic">Minerals (kg)</div>
          </Col>
          <Col span={2} className="center border-right">
            <div className="text-blue bold">{data['minerals']}</div>
          </Col>
          <Col span={4} className="center border-right">
            <div className="text-blue">{data['mineralsLimit']}</div>
          </Col>
          <Col span={6}>
            <div className="text-blue bold">Lượng chất béo cần kiểm soát</div>
            <div className="text-blue italic">Fat Control (kg)</div>
          </Col>
          <Col span={2} className="center">
            <div className="text-blue bold">{data['fatControl']}</div>
          </Col>
        </Row>
      </Main>
    </Section>
  )
}

export default Section02