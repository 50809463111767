import { GET, POST, PUT, DELETE } from '../services/axios'

export const find = async ({ page, limit, keyword, status }) => {
  return await GET(`/staffs?page=${page}&limit=${limit}&keyword=${keyword}&status=${status}`)
}

export const findOne = async (id) => await GET(`/staffs/${id}`)

export const create = async (formData) => await POST('/staffs', formData)

export const createBulk = async (formData) =>
  await POST('/staffs/import', formData)

export const update = async (id, formData) => await PUT(`/staffs/${id}`, formData)

export const remove = async () => await DELETE('/staffs')
